<template>
  <sidenav :custom_class="color" :class="[isRTL ? 'fixed-end' : 'fixed-start']" v-if="showSidenav" />
  <main class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden">
    <!-- nav -->
    <navbar :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :color="isAbsolute ? 'text-white opacity-8' : ''" :minNav="navbarMinimize" v-if="showNavbar" />
    <router-view />
  </main>
</template>
<script>
import Sidenav from "./common/Sidenav";
import Navbar from "@/common/Navbars/Navbar.vue";
import { mapMutations, mapState } from "vuex";
import $ from "jquery";
export default {
  name: "App",
  components: {
    Sidenav,
    Navbar
  },
  mounted() {
    this.checkWindowSize();
    window.addEventListener('resize', this.checkWindowSize);

    window.addEventListener('storage', (event) => {
      if (event.key === 'logout-event') {
          // Reload the current tab
          window.location.reload();
      }
    });
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
    checkWindowSize() {
        if (window.innerWidth < 1200) {
            $(".icon-close-sidenav-main").show();
        } else {
            $(".icon-close-sidenav-main").hide();
        }
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkWindowSize);
  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton"
    ]),
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";

    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
  }
};
</script>
